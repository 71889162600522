import React, { useEffect, useState } from 'react';
// import Header from './features/Header';
import { ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NewRouter from './NewRouter';
import { BrowserRouter as Router, Link } from 'react-router-dom';
// import GlobalLoading from './components/GlobalLoading';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { authAction, unitsAction,  modalAction } from './redux/actions'
// import { actModalChange, actModalIsOk, actResetModal } from './actions/modal';
// import { hideLoading } from './actions/ui';
import { faBell, faTools } from "@fortawesome/free-solid-svg-icons";

import './App.css';
// import ModalSupportOption from './components/SupportComponents/Modal/ModalOption';
import Header from './container/Unit/Header';
import GlobalLoading from './components/GlobalLoading';

import { getInformation } from './api/unit/user';
import { getBranchArea } from './api/branch';
import ModalSupportOption from './container/Unit/Modal/modalSupportOption';
import ModalNotify from './features/ModalNotify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SupportToolsComponent from './components/Unit/SupportTools/SupportTools';
import SupportSaveNoteComponent from './components/Unit/SupportSaveNote/SupportSaveNote';

function App() {

  const dispatch = useDispatch();
  const token = useSelector(state => state.token);
  const auth = useSelector(state => state.auth);

  const { name, avatar, role, branchID} = auth.user; //notification

  const firstLogin = localStorage.getItem('firstLogin');
  var [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (firstLogin) {
      const getToken = async () => {
        const res = await axios.post('/user/refresh_token', null);
        if (res.data.status === 0) {
          dispatch(modalAction.modalChange({
            type: "centered",
            title: "Block by Team IT",
            size: 'xs',
            content: <ModalSupportOption
                type={88}
                itemOption={{
                    color:"red",
                    title: res.data.msg
                }}
                button1="Log Out"
                typeButton1="btn-danger"
                featureButton1={() => handleLogout()}
            />
           }));
           openModal();
        }
        else dispatch(unitsAction.tokenRefresh(res.data.access_token));
      }
      getToken();
      
    }
  }, [auth.isLoggedIn, dispatch, firstLogin]);

  function closeModal() {
    //console.log('close modal');
    dispatch(modalAction.modalReset());
   }
  function openModal() { dispatch(modalAction.modalIsOk()); }

  const handleLogout = async () => {
    try {
      await axios.get('/user/logout');
      localStorage.removeItem('firstLogin');
      window.location.href = "/";

    } catch (error) {
      window.location.href = "/";
    }
  }

  // note

  useEffect(() => {

    const fetchUser = async (token) => {
      const res = await getInformation(token);
     // console.log("res", res)
      return res
    }

    const fetchBranchListSelect = async (token) => {
      const res = await getBranchArea();
      return res
    }

    //fetchBranchInformation

    if (token !== "") {
      const getUser = async () => {
        //    dispatch(actLogin()) // khi quay lại trình duyệt gán lại isLoggedIn = true;
        dispatch(authAction.login())
        fetchUser(token).then(res => {
          // if (res.data === null) {
          //   localStorage.removeItem('firstLogin');
          // } else {
          const payload = {
            "user": res.data,
            "isReception": res.data.role === 0 ? true : false,
            "isAdmin": res.data.role === 1 ? true : false,
            "isManage": res.data.role === 2 ? true : false,
            "isSuper": res.data.role === 3 ? true : false,
            "isAccount": res.data.role === 4 ? true : false,
            "isAccLeader": res.data.role === 5 ? true : false,
            "isAM": res.data.role === 6 ? true : false,
            "isCEO": res.data.role === 7 ? true : false,
            "isAccChief": res.data.role === 8 ? true : false,
          }
          dispatch(authAction.getUser(payload))
          //   dispatch(actGetUser(payload))
          // }
          //history.push("/home")

        });
        
        fetchBranchListSelect(token).then(res => {
          dispatch(unitsAction.fetchAllBranchInformation(res.data))
        })
      }
      getUser();

    }
  }, [token, dispatch]);

  return (
    <Router>
      <ModalNotify closeModal={closeModal} />
      <div className="App">
        <GlobalLoading />
        
        <div className="d-flex no-printer" style={{
          width: '200px',
          right: '0',
          top: '0',
          position: 'fixed',
          zIndex: '99',
          height: '35px',
          fontSize: '1.3rem',
          textAlign: 'center',
          alignItems: 'center',
        }}>

        {(branchID === 99 && role === 0) ?
          <div className="d-flex align-items-center mr-4 nav-bar__asset-notif">
            <i className="fa-solid fa-note-sticky nav-bar__icon"></i>
            <SupportSaveNoteComponent />
          </div> : ""}

          <div className="d-flex align-items-center mr-4 nav-bar__asset-notif">
            <FontAwesomeIcon icon={faTools} className="nav-bar__icon mr-2 " />
            <SupportToolsComponent />
          </div>

          <div className="d-flex">
            <span className="ml-2 margin-auto">{name}</span>
            <div className={firstLogin ? "d-flex align-items-center nav-bar__asset-user ml-3 mr-4" : "op-hidden"}>
              <img src={avatar}
                alt="avatar" style={{ width: '35px', height: '35px', borderRadius: '50%', verticalAlign: 'middle' }}></img>

              <div className="nav-bar__asset-user__click">
                <ul className="nav-bar__asset-user__menu">
                  {/* <li className="nav-bar__asset-user__item" >
                    <Link to="/user/guider" className="nav-bar__asset-user__text">Manual Used</Link></li>
                  <li className="nav-bar__asset-user__item" >
                    <Link to="/calculated_vat" className="nav-bar__asset-user__text">Calculate VAT</Link></li>
                  <li className="nav-bar__asset-user__item" >
                    <Link to="/user/profile" className="nav-bar__asset-user__text">User Profile</Link></li> */}
                  <li
                    className="nav-bar__asset-user__item "
                    onClick={handleLogout}
                  ><Link to="/" className="op-redcolor nav-bar__asset-user__text">Log Out</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex no-printer" >
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>


        <div className="justify-content-around" >

            <div className="d-flex no-printer" 
                  style={{
                    width: '120px',
                    left: '0px',
                    top: '0px',
                    height: '35px',
                    position: 'fixed',
                    zIndex: '99',
                    // backgroundColor: '#9999',
                    fontSize: '1.2rem',
                    textAlign: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
              onClick={() => setHidden(!hidden)}>
                <div className={firstLogin ? "d-flex align-items-center nav-bar__asset-user ml-3 mr-4" : "op-hidden"}>
                  <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1661231633/database/icon/menu.svg"
                    alt="avatar" style={{ width: '25px', height: '35px', verticalAlign: 'middle', color: 'red' }}></img>
                  <span className="ml-2"
                    style={{
                      fontSize: '1.5rem',
                      color: 'black',
                      fontWeight: 'bold',
                    }}>MENU</span>
                </div>
            </div>
          
          <div className={firstLogin ? (hidden ? "op-hidden" : "") : "op-hidden"}>
            <Header />
          </div>
          
          <NewRouter />
        </div>
      </div>
    </Router>
  );

}

export default App;
