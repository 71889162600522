import axios from 'axios';

//PATCH REQUEST
export const takeSchedule = (branchID, month, token) => {
    let newURL = "/schedule/" + branchID + "/take_schedule/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const takeListRoomSchedule = (branchID, month, token) => {
    let newURL = "/schedule/" + branchID + "/take_list_number_room/" + month;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const makeBookingOnSchedule = (branchID, data, token) => {
    let newURL = "/schedule/" + branchID + "/make_guest_on_reservation";
    return axios.post(`${newURL}`, data, {
        headers: { Authorization: token }
    });
}

//router.get('/:branchID/find_schedule/:datefrom/:dateto', scheduleCtrl.findNewCodeSchedule);

export const findGuestDataOnSchedule = (branchID, day1, day2, token) => {
    let newURL = "/schedule/" + branchID + "/find_schedule/" + day1 + "/" + day2;
 //   let newURL = "/schedule/" + branchID + "/find_schedule_test2/" + day1 + "/" + day2;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const takeGuestList = (branchID, selectTab, day, token) => {
    let newURL = "/schedule/" + branchID + "/find_guest_list/" + selectTab + "/" + day;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}

export const findGuestComBankWithDay = (id, token) => {
    let newURL = "/schedule/find_guest_come_back/" + id;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    });
}



