import React, { useEffect, useRef, createContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Component
import MakeRoomDataRoom from '../../components/MakeRoom/MakeRoomDataRoom';
import MakeRoomDataGuest from '../../components/MakeRoom/MakeRoomGuest';

// API & Action & Unit
import * as bookingApi from '../../api/booking';
import * as branchApi from '../../api/branch';
import * as invoiceApi from '../../api/invoice';
import * as guestApi from '../../api/guest';
import { modalAction, makeRoomAction, unitsAction } from '../../redux/actions';

import { informationToast, errorToast, } from '../../utils/Toast/index'; // warningToast
import ModalSupportOption from '../Unit/Modal/modalSupportOption';
import ModalNotify from '../../features/ModalNotify';
import MakeRoomFooterButton from '../../components/MakeRoom/MakeRoomOther/MakeRoomFooterButton';
import { changeStringVNDtoNumber  } from '../../utils/Currency/SupportCurrency'
import './makeRoom.css';
import ViewPassPortGuest from '../../components/MakeRoom/ViewPassPortGuest/ViewPassPortGuest';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ListEditLog from '../../components/MakeRoom/Other/ListEditLog';
  
const style = {
    container: {
        textAlign: "left",
        margin: 5,
        backgroundColor: '#d8dbdd'
    },
    icon: {
        color: 'blue',
        fontSize: '1.4rem',
        textAlign: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: 5,
        marginLeft: 5,
    },
    children: {
        border: '0.5px solid rgba(0,255,255,0.8)',
        padding: '4px'
    },
    label: {
        fontSize: '1.3rem',
        width: '120px',
        margin: 0,
        padding: 0,
        fontWeight: '500'
    },
    textarea: {
        width: '465px',
        fontSize: '1.4rem',
    },
    text: {
        fontSize: '1.4rem'
    },
    time: {
        fontSize: '1.4rem',
        padding: '4px',
        width: '20px'
    }
};

export const UserContext = createContext();

function MakeRoomContainer() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    const makeRoomOnState = useSelector(state => state.makeRoom);
    const makeRoomService = useSelector(state => state.makeRoomOther);
    const listChangeGuest = useRef({});
    const { branchID, roomSelect, checkin, idRoom, typeRoom, id_history } = useParams();

    const [listHistory, setListHistory] = useState([]);
    const [selectHistoryIndex, setSelectHistoryIndex] = useState(0);

    const auth = useSelector(state => state.auth);
    const [viewFullPassPort, setViewFullPassPort] = useState(false);
    const [click, setClick] = useState(true);
    const { user } = auth;
    let history = useHistory();
   
    useEffect(() => {
        if (!branchID) {
            alert('Error when make room');
        } else {
            if (token !== "") {
                if(id_history === undefined) {
                    if (typeRoom === undefined) {
                        if (idRoom === undefined) {
                            document.title = `New:${roomSelect}-(${checkin})`;
                            dispatch(makeRoomAction.takaDataBookingBySelect({ branchID, roomSelect, checkin }));
                        } else if (idRoom === "make_waiting") {
                            dispatch(makeRoomAction.takaDataBookingByWaitingList({ branchID }));
                        } else {
                            dispatch(makeRoomAction.takeDataBookingByID({ branchID, id: idRoom }));
                        }
                    } else {
                        dispatch(makeRoomAction.takaDataBookingByWaitingList({ branchID }));
                        checkRoomAvailable(token, "new_reservation");
                    }
                } else {
                    // 
                    const loadServer = async () => {
                        try {
                            const response = await bookingApi.getListHistoryOnLog(branchID, id_history, token);
                            if(response) {
                                setListHistory(response.data.data);
                                setSelectHistoryIndex(response.data.data[response.data.data.length - 1]._id)
                            } else return errorToast("Error when load history")
          
            
                            window.title = "History"
                        } catch (error) {
                            console.error(error);
                            return errorToast("Error when load history")
                        }
                    }

                    loadServer();
                }
            } 
        }
        
    }, [token]);

    useEffect(() => {
        if(token) {
            console.log("vo here check selectHistoryIndex")
            dispatch(makeRoomAction.takeHistoryByID({ branchID, id: selectHistoryIndex }));
        }
    },[selectHistoryIndex]);

    // console.log("listChangeGuest", listChangeGuest)
    function handleChangeListChangeGuest(value) {
        listChangeGuest.current[value._id] = {};
        listChangeGuest.current[value._id] = value;
    }

    function handleDeleteListChangeGuest(value) {
        if(value._id in listChangeGuest.current) delete listChangeGuest.current[value._id];
        // props.onChangeValueMakeRoom(index, "indexGuestSelect")
    }

    function onChangeValueMakeRoom(value, target, option) {
        //makeRoomService.confirm
        
        if (value === "company" && target === "payment") {
            dispatch(makeRoomAction.changeChiddenMakeRoom({ value, target, option }))
            dispatch(makeRoomAction.changeChiddenMakeRoomOther({ value: true, target: "vat", option: "" }))
        } else if(target === "viewPassPort") {
            dispatch(makeRoomAction.changeChiddenMakeRoom({ value: !makeRoomOnState.viewPassPort, target, option }))
        } else if(target === "request1") {
            if(option === "tap") {
                dispatch(makeRoomAction.changeChiddenMakeRoom({  value, target, option: undefined }))
            } else {
                if(!makeRoomOnState.request1.includes(value) && option === "extend"){
                    if(option === "extend") {
                        if(makeRoomOnState.request1 === "") dispatch(makeRoomAction.changeChiddenMakeRoom({  value , target, option }))
                        else dispatch(makeRoomAction.changeChiddenMakeRoom({  value : makeRoomOnState.request1 + ", " +value, target, option }))
                    } 
                } else {
                    if(makeRoomOnState.request1 === "") dispatch(makeRoomAction.changeChiddenMakeRoom({  value , target, option }))
                    else {
                        if(option === 0 ) return dispatch(makeRoomAction.changeChiddenMakeRoom({  value : "", target, option: undefined }));
    
                        const REQUEST_CASE = [
                            "",
                            "Company have contract 5% OFF",
                            "Company have contract free laundry 120.000vnd/day",
                            "Company have contract 5% OFF + free laundry 120.000vnd/day",
                        ]
    
                        const REQUEST_CASE2 = [
                            "",
                            "Company have contract 5% OFF",
                            "Company have contract free laundry 5.5$/day",
                            "Company have contract 5% OFF + free laundry 5.5$/day",
                        ]
    
                        let request1Value
                        let checkValueCase = makeRoomOnState.request1;
    
                        const check3 = branchID !== 11 ? makeRoomOnState.request1.includes(REQUEST_CASE[3]) : makeRoomOnState.request1.includes(REQUEST_CASE2[3]);
                        const check2 = branchID !== 11 ? makeRoomOnState.request1.includes(REQUEST_CASE[2]) : makeRoomOnState.request1.includes(REQUEST_CASE2[2]);
                        const check1 = branchID !== 11 ? makeRoomOnState.request1.includes(REQUEST_CASE[1]) : makeRoomOnState.request1.includes(REQUEST_CASE2[1]);
    
                        if(check3) checkValueCase = checkValueCase.replace(REQUEST_CASE[3], "$CHANGE"); 
                        else if (check2) checkValueCase = checkValueCase.replace(REQUEST_CASE[2], "$CHANGE");
                        else if (check1) checkValueCase = checkValueCase.replace(REQUEST_CASE[1], "$CHANGE");
                        else return dispatch(makeRoomAction.changeChiddenMakeRoom({  value : value + ", " + makeRoomOnState.request1, target, option: undefined }));
    
                        request1Value = checkValueCase.replace("$CHANGE", value);
    
                        dispatch(makeRoomAction.changeChiddenMakeRoom({  value : request1Value, target, option: undefined }))
                        }
                    
                }
            }

        } 
        // else if (value === "" && target === "price")  {
        //     dispatch(makeRoomAction.changeChiddenMakeRoom({ value: 0, target, option }))
        // } 
        else dispatch(makeRoomAction.changeChiddenMakeRoom({ value, target, option }))
    }

    function checkRoomAvailable(token, type) {
        // call api check room with type

        const onMoveRoomToSchedule = async (roomSelect) => {
           // alert("vo here"+ roomSelect)
            const result = await bookingApi.changeRoomAfterCheckWithType(branchID, idRoom, roomSelect, token);
            if(result){
                if (result.data.status === 1) {
                    informationToast("" + result.data.msg); 
                    window.location.reload();}
                else return errorToast("Error when change room")
            } else return errorToast("Error when change room")
        }

        const onMakeRoomKeepWithList = async (listRoomSelect, roomMain,  checkin, checkout, nights) => {
            //alert("ROom list :" + listRoomSelect)
          //  window.location = `/room/${branchID}/${roomMain}/${checkin}`;
         
            closeModal();
            // make new list Schedule
            // const { checkin, checkout, nights } = dataBooking;
            const newListSchedule =[];
            if (listRoomSelect.length > 0) {
                function checkRoomWithDiscount(price, discount) {
                    // console.log("price", price)
                    // console.log("discount", discount)
                    if (discount === "0" || discount === undefined) return price;
                    else return Math.round(price * (1 - Number(discount) / 100) * 100) / 100;
                }

                // call api take room price list 

                listRoomSelect.forEach(item => {
                    newListSchedule.push({
                            roomSelect: item,
                            move: false,
                            discount: 0,
                            price: checkRoomWithDiscount(changeStringVNDtoNumber(makeRoomOnState.listRoomPrice[item]) ,makeRoomOnState.listSchedule[0].discount),
                            checkin: type === "checkRoomStatus" ? 
                                    new Date(checkin.slice(0, 10) + "@"+makeRoomOnState.listSchedule[0].timeEarly)
                                    : new Date(checkin.slice(0, 10)),
                            checkout: type === "checkRoomStatus" ? 
                                    new Date(checkout.slice(0, 10) + "@" + makeRoomOnState.listSchedule[0].timeLateOut)
                                     : new Date(checkout.slice(0, 10)),
                            nights: nights,
                            discount: makeRoomOnState.listSchedule[0].discount,
                            shortime: false,
                            earlyCheckin: false,
                            lateOut: false,
                            timeEarly: type === "checkRoomStatus" ? makeRoomOnState.listSchedule[0].timeEarly : checkin.slice(12, 20),
                            timeLateOut: type === "checkRoomStatus" ? makeRoomOnState.listSchedule[0].timeLateOut : checkout.slice(12, 20),
                            timeShortTime: "",
                        })
                })
            } 
            const typeRoom = undefined;
            window.title = "Make Room"

            if (type ==="new_reservation") {
                history.push(`/room/${branchID}/${roomMain}/${checkin.slice(0, 10)}`);
            }

           dispatch(makeRoomAction.changeChiddenMakeRoom({ value: newListSchedule, target: "newListSchedule", option: undefined }));
          
            // roomSelect: "605"
            // move: false
            // discount: 0
            // price: makeRoomOnState.listRoomPrice(listRoomSelect[0])
            // checkin: "2024-04-02"
            // checkout: ""
            // nights: ""
            // shortime: false
            // earlyCheckin: false
            // lateOut: false
            // timeEarly: "15:00"
            // timeLateOut: "12:00"
            // timeShortTime: ""
        }

        const onLoadData = async (token) => {
            let result;

            if(type!=="checkRoomStatus") {
                result = await bookingApi.checkRoomWithType(branchID, idRoom, typeRoom, token);
            } else {
                result = await bookingApi.checkRoomWithCalender(branchID, "checkRoomStatus", "new", {
                    checkin: makeRoomOnState.listSchedule[0].checkin,
                    checkout: makeRoomOnState.listSchedule[0].checkout,
                },token);
            }

            // console.log("result", result);
            if(result || type === "checkRoomStatus") {
                if(result.data.status === 1 || result.data.status === 4) {
                    dispatch(modalAction.modalChange({
                        type: "centered",
                        title: "Room with type have available",
                        size: result.data.status === 1 ?  'xs' : 'xl',
                        backdrop:"static",
                        // featureButton1:{ () },
                        content: < ModalSupportOption
                            type={16} 
                            featureButton1={result.data.status === 1 ? onMoveRoomToSchedule : onMakeRoomKeepWithList}
                            featureButton2={() => window.open(`/room/${branchID}/${idRoom}`, '_self')}
                            itemOption={{
                                type : result.data.status === 1 ? "roomAvailability" : "roomSuggest",
                                typeRoomRequest: typeRoom,
                                schedule: result.data.dataReturn,
                            }}
                        />
                    }));
                    openModal();
                }
                else if (result.data.status === 2) { window.open(`/room/${branchID}/${idRoom}`, '_self') }
                else if(result.data.status === 3) {
                    dispatch(modalAction.modalChange({
                        type: "centered",
                        title: "Room information",
                        size: 'xs',
                        content: < ModalSupportOption
                            type={88}
                            itemOption={{
                                color: "red",
                                title: <>
                                    <h3>{result.data.msg}</h3>
                                    <div className="mt-2">Click goto this room (delete):  
                                        <button className="btn btn-primary"
                                            onClick={()=>window.open(`/room/${branchID}/${idRoom}`,'_self')}>Go to Room</button>
                                    </div>
                                </>
                            }}

                        />
                    }));
                    openModal();
                };

            } else return errorToast("Error when load room data")
            // console.log("reult", result);
        }

        onLoadData(token);
    }

    function onSortListSchedule() {

        const onChangeSortListSchedule = async (data) => {
             dispatch(makeRoomAction.changeChiddenMakeRoom({ value: data, target: "sortListSchedule"  }));
             closeModal();
        }

        dispatch(modalAction.modalChange({
            type: "centered",
            title: "Sort List Schedule",
            size: 'xs',
            content: < ModalSupportOption
                type={13}
                // eslint-disable-next-line no-use-before-define
                featureSupport1={onChangeSortListSchedule}
                featureSupport2={closeModal}
                itemOption={{ 
                    schedule: makeRoomOnState.listSchedule, 
                    code: "sort"
                }}
            />
        }));
        openModal();

    }
    
    function onAddMoreSameListSchedule() {

        function onSelectRoom(data, type) {
            if(data.length > 0) {
                const newData = { ...makeRoomOnState };

                data.forEach(roomSelect => {

                    if(type === "notSame") {
                        newData.listSchedule.push({
                            ...makeRoomOnState.listSchedule[0],
                            booking_id: undefined,
                            price: roomSelect.price,
                            roomSelect: roomSelect.roomSelect
                        });
                    } else {
                        newData.listSchedule.push({
                            ...makeRoomOnState.listSchedule[0],
                            booking_id: undefined,
                            roomSelect,
                        });
                    }
                    
                    newData.scheduleSelect += 1;
                })
                onChangeAllDataValueMakeRoom(newData);
            }
            closeModal();
        }
        const onChangeAddMoreSameListSchedule = async (dataCheck) => {
            const result = await bookingApi.automaticCheckRoom(branchID, {
                checkin: makeRoomOnState.listSchedule[0].checkin,
                checkout: makeRoomOnState.listSchedule[0].checkout,
                roomSelect: makeRoomOnState.listSchedule[0].roomSelect,
                price: makeRoomOnState.listSchedule[0].price,
                numberRoomRequest : dataCheck.request.room
            } ,token)
           // dispatch(makeRoomAction.changeChiddenMakeRoom({ value: data, target: "sortListSchedule"  }));
          //   closeModal();

          dispatch(modalAction.modalChange({
            type: "centered",
            title: "Sort List Schedule 2",
            size: 'xs',
            content: <ModalSupportOption
                type={14}
                // eslint-disable-next-line no-use-before-define
                featureSupport1={onChangeAddMoreSameListSchedule}
                featureSupport2={closeModal}
                featureButton1={onSelectRoom}
                itemOption={{ 
                    schedule: result.data.dataReturn, 
                    checkin: makeRoomOnState.listSchedule[0].checkin,
                    checkout: makeRoomOnState.listSchedule[0].checkout,
                    listRoomNumber: makeRoomOnState.listRoomNumber,
                    listRoomPrice: makeRoomOnState.listRoomPrice,
                    code: "same"
                }}
            />
          }));
       }

       dispatch(modalAction.modalChange({
           type: "centered",
           title: "Sort List Schedule",
           size: 'xs',
           content: <ModalSupportOption
               type={14}
               // eslint-disable-next-line no-use-before-define
               featureSupport1={onChangeAddMoreSameListSchedule}
               featureSupport2={closeModal}
               featureButton1={onSelectRoom}

               itemOption={{ 
                   schedule: [], 
                   checkin: makeRoomOnState.listSchedule[0].checkin,
                   checkout: makeRoomOnState.listSchedule[0].checkout,
                   listRoomNumber: makeRoomOnState.listRoomNumber,
                   listRoomPrice: makeRoomOnState.listRoomPrice,
                   code: "same"
               }}
           />
       }));
       openModal();
    }

    function onChangeGuestDataAfterEdit(dataChange, index) {
        dispatch(makeRoomAction.updateDataGuestAfterEditMakeRoom({data: dataChange,index}))
    }

    function onChangeAllDataValueMakeRoom(value) {
        dispatch(makeRoomAction.changeAllDataChiddenMakeRoom(value));
    }

    function onConfirmDeleteSchedule(index) {
        const codeDelete = branchID + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

        if (index === undefined) {
            dispatch(modalAction.modalChange({
                type: "centered",
                title: "Confirm Delete ALL Schedule.",
                size: 'xs',
                content: < ModalSupportOption
                    type={9}
                    featureSupport1={onDelete}
                    featureSupport2={closeModal}
                    itemOption={{ schedule: makeRoomOnState.listSchedule, code: codeDelete }}
                />
            }));
            openModal();
        } else {
            if (makeRoomOnState.listSchedule[index].checkin === "" || makeRoomOnState.listSchedule[index].checkout === "") {
                onDelete();
            } else {
                dispatch(modalAction.modalChange({
                    type: "centered",
                    title: "Confirm Delete Schedule.",
                    size: 'xs',
                    content: < ModalSupportOption
                        type={9}
                        featureSupport1={onDelete}
                        featureSupport2={closeModal}
                        itemOption={{ schedule: makeRoomOnState.listSchedule[index], code: codeDelete }}
                    />
                }));

                openModal();
            }
        }

        function onDelete() {

            const deleteScheduleOnServer = async () => {
                dispatch(unitsAction.showLoading())
                try {
                    const resultDelete = await bookingApi.deleteScheduleById(branchID, makeRoomOnState.listSchedule[0].booking_id, token);
                    if (resultDelete) {
                        if (resultDelete.data.status === 1) {
                            informationToast("" + resultDelete.data.msg);
                            if (resultDelete.data.data === "cancel") return window.open(`/room/${user.branchID}/${idRoom}`, '_self');
                            else return window.open(`/reservation`, '_self');
                        } else errorToast("" + resultDelete.data.msg);
                    } else return errorToast("Error when release booking");
                } catch (error) {
                    errorToast("Error when release booking");
                }
                dispatch(unitsAction.hiddenLoading())
            };

            if (index === undefined) {
                deleteScheduleOnServer()
            } else onChangeValueMakeRoom("delete", "listSchedule", index);

            closeModal();
        }

        setClick(true);

    }

    function onViewMailFormat() {
        dispatch(modalAction.modalChange({
            type: "centered",
            title: "Mail Format",
            size: 'xl',
            content: < ModalSupportOption
                type={17}
                itemOption = {{
                    type : makeRoomOnState.listGuestSelect[0].name.includes("KEEP")? "suggestRoom" : "makeRoom",
                    branchID: branchID
                }}
            />
        }));

        openModal()
    }

    function onOpenSettingRequest1() {
        const onSaveData = async (data) => {
            try {
                const result2 = await branchApi.changeBookingRequest1(branchID, data, token);
                if (!result2) return errorToast("Error when load data");
                else {
                    informationToast(result2.data.msg);
                    // change on client
                    dispatch(makeRoomAction.changeChiddenMakeRoom({ value: data, target: "listRequest1" }));
                }
                closeModal();
            } catch (error) {
                errorToast("Error when load data ")
            }
        }

        const takeBookingRequest1 = async () => {
            try {
                dispatch(unitsAction.showLoading());
                const result = await branchApi.findElementBranch(branchID, "bookingRequest1", token);
                dispatch(unitsAction.hiddenLoading());
                if (!result) return errorToast("Error when load data");
                else {
                    dispatch(modalAction.modalChange({
                        type: "centered",
                        title: "Edit Quick Support Request 1",
                        size: 'xs',
                        content: < ModalSupportOption
                            type={8}
                            featureSupport1={onSaveData}
                            featureSupport2={closeModal}
                            itemOption={result.data.data}
                        />
                    }));
                }
            } catch (error) {
                errorToast("Error when load data")
            }
        }

        takeBookingRequest1();
        openModal();
    }

    function onChangeValueMakeRoomOther(value, target, option) {
        dispatch(makeRoomAction.changeChiddenMakeRoomOther({ value, target, option }));
        // check pickup / dropoff have all infoamtion
        // => fill text on 
        
        // if(target ==="pickup" || target ==="dropoff") {
            
        // }
    }

    function updateListGuest(value) {
       // console.log("value",value)
        dispatch(makeRoomAction.changeChiddenMakeRoom({ value, target: "listGuestSelect" }));
        
    }

    function onConfirmCheckRoomStatus() {
        checkRoomAvailable(token,"checkRoomStatus");
    }

    function onConfirmMakeRoom() {
        dispatch(unitsAction.showLoading());
        setClick(false);

        if (makeRoomService.confirm) {
            makeRoomService.status = "confirm";
        }
        const newData = { ...makeRoomService, ...makeRoomOnState, };
        delete newData["listRequest1"];
        delete newData["listRoomNumber"];
        delete newData["createNew"];
        delete newData["timeEdit"];
        delete newData["deviceEdit"];

        //    delete newData["listRoomPrice"];
        delete newData["loading"];

        const callApiMakeBookingOnSchedule = async () => {
            try {
                //  dispatch(unitsAction.showLoading());
                const result = await bookingApi.makeNewBooking(branchID, newData, token);
                //     dispatch(unitsAction.hiddenLoading());
                if (result) {
                    if (result.data.status === 1) {
                        //  result.data.data
                        informationToast(result.data.msg);
                        dispatch(unitsAction.delay());
                        window.open(`/room/${user.branchID}/${result.data.data}`, '_self');
                    };
                    if (result.data.status === 2) return errorToast(result.data.msg);
                    if (result.data.status === 3) {
                        dispatch(modalAction.modalChange({
                            type: "centered",
                            title: "Double Schedule Booking, Please check again",
                            size: 'xs',
                            content: <ModalSupportOption
                                type={12} //  12 = ModalShowBillDouble
                                button1="View Room Details"
                                button2="Close"
                                typeButton1="btn-primary"
                                typeButton2="btn-danger"
                                featureButton1={() => window.open(`/room/${user.branchID}/${result.data.data.booking_id}`, '_blank')}
                                featureButton2={closeModal}
                                itemOption={result.data.data}
                            />
                        }));
                        openModal();
                    }
                } else {
                    errorToast("Error when update Room, please check again.")
                }
            } catch (error) {
                errorToast("Error when update Room, please check again.")
            }
        }

        const callApiCheckMultiGuest = async () => {
            const dataGuest = await guestApi.checkMultiGuestChange(listChangeGuest.current, token);
            //console.log("dataGuest", dataGuest)
            if(dataGuest.data.status === 1) {
                callApiMakeBookingOnSchedule();
            } else {
                errorToast(dataGuest.data.msg);
                setClick(true);
            }
        }

        const listIDOnListChange = Object.keys(listChangeGuest.current);
       // console.log("listIDOnListChange",listIDOnListChange)
        if( listIDOnListChange.length > 0 ) {
          //alert("Check data guest change")
          callApiCheckMultiGuest();
          setClick(true);
        } else callApiMakeBookingOnSchedule();
        dispatch(unitsAction.hiddenLoading());
        setClick(true);
    }

    function onConfirmEdit() {
        setClick(false);
        if (makeRoomService.confirm) {
            makeRoomService.status = "confirm";
        }
        const newData = { 
            ...makeRoomOnState, 
            ...makeRoomService 
        };

        delete newData["listRequest1"];
        delete newData["listRoomNumber"];
        delete newData["signature"];
        delete newData["listBranchArea"];
        delete newData["indexGuestSelect"];

        const callApiEditBooking = async () => {
            try {
                const result = await bookingApi.updateBookingById(branchID, idRoom, newData, token);
                // console.log("result", result);
                
                if (result) {
                    switch (result.data.status) {
                        case 1: {
                            // return console.log("result", result);
                            informationToast(result.data.msg)
                            dispatch(unitsAction.delay());
                            return window.open(`/room/${user.branchID}/${makeRoomOnState.listSchedule[0].booking_id}`, '_self');
                           // return window.open(`/room/${user.branchID}/${idRoom}`, '_self');

                        }
                        case 2: return errorToast(result.data.msg);
                        case 3: return errorToast(result.data.msg + " Booking ID:" + result.data.data.id);
                        case 4: return informationToast("Have change data guests")//warningToast(result.data.msg);
                        default: break;
                    }
                } else {
                    errorToast("Error when update Room, please check again.");
                }
            } catch (error) {
                errorToast("Error when update Room, please check again.")
            }

        }

        const callApiCheckMultiGuest = async () => {
            let dataGuest ;
            const listIDOnListChange = Object.keys(listChangeGuest.current);
            dispatch(unitsAction.showLoading());

            if( listIDOnListChange.length > 0 ) { 
                // console.log("vo here 123")
                //console.log("listChangeGuest.current", listChangeGuest.current)
                dataGuest = await guestApi.checkMultiGuestChange(listChangeGuest.current, token);
            } else {
                // change to object
                // console.log("vo here 234")
                let dataPost = {};
                if (makeRoomOnState.listGuestSelect.length > 0) {
                    makeRoomOnState.listGuestSelect.forEach(dataGuest => {
                        dataPost[""+ dataGuest._id] = dataGuest;
                    });
                   // console.log("dataPost", dataPost)
                    dataGuest = await guestApi.checkMultiGuestChange(dataPost, token);
                } else {
                    dataGuest.data = {};
                    dataGuest.data.status = 1;
                }
            }
            dispatch(unitsAction.hiddenLoading());
          // console.log("dataGuest", dataGuest)
            if(dataGuest.data.status === 1) {
                callApiEditBooking();
            } else errorToast(dataGuest.data.msg);
        }
        callApiCheckMultiGuest();

        setClick(true);
    }

    function onViewHistory() {
        const loadHistory = async () => {
            try {
                const dataResult = await bookingApi.takeBookingHistory(branchID, idRoom, token);
               // console.log("dataResult",dataResult);
                dispatch(modalAction.modalChange({
                    type: "centered",
                    title: "View History Booking data",
                    size: 'xl',
                    content: < ModalSupportOption
                        type={5}
                        // featureSupport1={onSaveData}
                        featureSupport2={closeModal}
                        itemOption={{ data: dataResult.data.data, type: "bookingEditStory" }}
                    />
                }));
            } catch (error) {
                errorToast("Error when view booking history");
            }
        }

        loadHistory();
        openModal();
    }

    function onPrintBooking() {
        window.open(`/make_booking_bill/${user.branchID}/group/${idRoom}`, '_blank');
    }

    function onConfirmRedo() {

        if (makeRoomService.confirm) {
            makeRoomService.status = "confirm";
        }
        const newData = { ...makeRoomService, ...makeRoomOnState, };
        delete newData["listRequest1"];
        delete newData["listRoomNumber"];
        delete newData["createNew"];
        //    delete newData["listRoomPrice"];
        delete newData["loading"];

        const loadSchedule = async () => {
            try {
                dispatch(unitsAction.showLoading());
                const dataResult2 = await bookingApi.takeBookingDataById(branchID, idRoom, token);
                newData.listSchedule.forEach((item, index) => {
                    newData.listSchedule[index].name = dataResult2.data.data.listGuestSelect[0].name;
                });

            //    console.log("newdata", newData);

                dispatch(modalAction.modalChange({
                    type: "centered",
                    title: "Confirm Redo Booking",
                    size: 'lg',
                    content: < ModalSupportOption
                        type={10}
                        featureSupport1={loadRedoRequest}
                        featureSupport2={closeModal}
                        featureButton2={""}
                        featureButton3={""}
                        featureButton4={""}
                        itemOption={{
                            data: newData.listSchedule,
                            isAddService: false,
                            group_id: dataResult2.data.group_id,
                            invoice_id : "",
                            type: "redoInvoice",
                            search: false,
                        }}
                    />
                }));
                dispatch(unitsAction.hiddenLoading());
                
            } catch (error) {
                errorToast("Error when Check Out");
            }
        }

        // check room select && room price
        loadSchedule();
        openModal();

        const loadRedoRequest = async () => {
            try {
                const dataResult = await bookingApi.redoGuest(branchID, idRoom, makeRoomService.area, newData, token);

                console.log("dataResult", dataResult);
                
                if(dataResult) {
                    if(dataResult.data.status === 1) {
                        informationToast(dataResult.data.msg);
                        dispatch(unitsAction.delay());
                        dispatch(unitsAction.delay());
                        window.open("/room/"+branchID+"/"+idRoom,'_self');
                    }
                    else if(dataResult.data.status === 3) {
                        
                        dispatch(modalAction.modalChange({
                            type: "centered",
                            title: "Double Schedule Booking, Please check again",
                            size: 'xs',
                            content: < ModalSupportOption
                                type={12} //  12 = ModalShowBillDouble
                                button1="View Room Details"
                                button2="Close"
                                typeButton1="btn-primary"
                                typeButton2="btn-danger"
                                featureButton1={() => window.open(`/room/${user.branchID}/${dataResult.data.data.booking_id}`, '_blank')}
                                featureButton2={closeModal}
                                itemOption={dataResult.data.data}
                            />
                        }));
                        openModal();
                        
                    } else if (dataResult.data.state === 2) {
                        dispatch(modalAction.modalChange({
                            type: "centered",
                            title: "Double Schedule Booking, Please check again",
                            size: 'xs',
                            content: < ModalSupportOption
                                type={88} //  12 = ModalShowBillDouble
                                itemOption={{
                                    title: dataResult.data.msg,
                                    color:"red"
                                }}
                            />
                        }));
                        openModal();
                    }
                }else errorToast("Error when redo guest");
            } catch (error) {
                errorToast("Error when redo guest");
            }
        }
    }

    function onConfirmMakeHotelInvoice(typeInvoice) {
        let keepGroupID = makeRoomOnState.group_id;

        const onChangeGroupID = async (value) => {
            if (value.length === 10) {
                const dataResult4 = await bookingApi.updateGroupId(branchID, idRoom, { value }, token);
                if (dataResult4) {
                    if (dataResult4.data.status === 1) {
                        informationToast("" + dataResult4.data.msg);
                        onChangeValueMakeRoom(value, "group_id");
                        keepGroupID = value;
                        loadSchedule(value);
                    }
                    else errorToast("" + dataResult4.data.msg);
                } else errorToast("" + dataResult4.data.msg);
            } else {
                errorToast("Please check again : group ID length = 10")
            }
        }

        function onOpenAddService() {
            window.open(`/add_service/${user.branchID}/${idRoom}`, '_blank');
        }

        function onOpenMakeHotelInvoice() {
            if(typeInvoice === "Booking") {
                window.open(`/make_booking_bill/${user.branchID}/group/${idRoom}`, '_blank');
            } else if(typeInvoice === "Cancel") {
                window.open(`/make_invoice/${user.branchID}/${idRoom}`, '_blank');
            } else if(typeInvoice === "PMRQ") {
                window.open(`/make_pmrq/${user.branchID}/group/${idRoom}`, '_blank');
            } else window.open(`/make_invoice/${user.branchID}/group/${idRoom}`, '_blank');
        }

        function onReload(id) {
            dispatch(modalAction.modalChange({
                type: "centered",
                title: "Confirm Check Out",
                size: 'lg',
                content: < ModalSupportOption
                    type={99}
                />
            }));

            loadSchedule(id);
        }

        const loadSchedule = async (id) => {
            try {
                dispatch(unitsAction.showLoading());
                const dataResult2 = await bookingApi.takeBookingScheduleListById(branchID, id, typeInvoice ? typeInvoice : "HI", token);
                // Check number invoice => block when have number invoice
                const checkInvoice = await invoiceApi.checkCountHotelInvoiceNumberWithBookingID(branchID, idRoom, token);
                // if booking only on schedule => print the booking
                if(typeInvoice === "Booking") {
                    if(dataResult2.data.data.length ===1 ) {
                        dispatch(unitsAction.hiddenLoading());
                        closeModal();
                        return window.open(`/make_booking_bill/${user.branchID}/${idRoom}`, '_blank');
                    }
                }

                if(typeInvoice ==="PMRQ" && checkInvoice.data.msg ==="Make new") {
                    dispatch(modalAction.modalChange({
                        type: "centered",
                        title: "Make Payment Request",
                        size: 'xs',
                        content: < ModalSupportOption
                            type={88}
                            featureSupport1={""}
                            featureSupport2={closeModal}
                            itemOption={{
                                title: <>
                                    <div>Have not Hotel Invoice, Please request Reception make it.</div>
                                    <div>Không có số Hotel Invoice, xin hãy báo lễ tân thêm.</div>
                                </>,
                                color: "red"
                            }}
                    />
                    }))
                } else {
                    dispatch(modalAction.modalChange({
                        type: "centered",
                        title: typeInvoice ==="PMRQ" ? "Make Payment Request" :
                                    typeInvoice ==="Booking" ?  "Confirm make Booking confirm" :"Confirm Check Out",
                        //size: 'lg',
                        size: 'xl',
                        content: <ModalSupportOption
                            type={10}
                            featureSupport1={() => onReload(keepGroupID)}
                            featureSupport2={closeModal}
                            featureButton2={onOpenAddService}
                            featureButton3={onChangeGroupID}
                            featureButton4={onOpenMakeHotelInvoice}
                            itemOption={{
                                data: dataResult2.data.data,
                                isAddService: checkInvoice.data.status === 1,
                                group_id: dataResult2.data.group_id,
                                invoice_id : checkInvoice.data.status === 1 ? "empty invoice..." : checkInvoice.data.data.invoice_id,
                                type: "makeHotelInvoice",
                                search: false,
                                typeInvoice
                            }}
                        />
                    }));
                }

                dispatch(unitsAction.hiddenLoading());
            } catch (error) {
                errorToast("Error when Check Out");
            }
        }

        if(typeInvoice === "Cancel") {
            window.open(`/make_invoice/${user.branchID}/${idRoom}`, '_blank');
        } else {
            loadSchedule(makeRoomOnState.group_id);
            openModal();
        }
    }

    function onReturnDefaultSignature() {
        const callAPICheckOldData = async () => {
            const result = await branchApi.findElementBranch(branchID,"signature", token);
            onChangeValueMakeRoomOther(result.data.signature,"signature",{index:"", target:"return"})
        }
        callAPICheckOldData()
    }

    function changeViewPassPort() {
        
    }

    function onUpdateSignature() {
        const callAPIUpdateSignature = async () => {
            const dataUpdate = makeRoomService.signature;
            const result = await branchApi.updateElementBranch(branchID, dataUpdate, "signature", token);
          //  console.log("result,", result)
        }
        callAPIUpdateSignature()
    }

    function closeModal() { dispatch(modalAction.modalReset()); }
    function openModal() { dispatch(modalAction.modalIsOk()); }

    return (
        <UserContext.Provider value={
            {
                listChangeGuest,
                handleChangeListChangeGuest,
                handleDeleteListChangeGuest,
                style,
            }
        }>
            <ModalNotify closeModal={closeModal} />
            {
                <div className="makeRoom">

                    <div className={'makeRoom-back_home d-flex'}
                        onClick={() => history.push(`/reservation`)}>
                        <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1661232117/database/icon/home.svg"
                            alt={"home"}
                            style={{ width: '25px', height: '27px', verticalAlign: 'middle', color: 'red' }} />
                        <div style={{ marginLeft: '2px' }}>RESERVATION</div>
                    </div>

                    <h1 className="mt-5 makeRoom-title">{!id_history ? "BOOKING INFORMATION" : "BOOKING EDIT HISTORY"}</h1>
                    {
                        id_history && <h3>
                            <>Time make: {makeRoomOnState["timeEdit"]}</>
                            <> -- Device Edit code: {makeRoomOnState["deviceEdit"]}</>
                        </h3>
                    }

                    <div style={{ padding: '0px 24px' }} >

                        <div className="row mb-3">
                            <ListEditLog    
                                selectHistoryIndex={selectHistoryIndex}
                                setSelectHistoryIndex={setSelectHistoryIndex}
                                listHistory = {listHistory}/>

                            <MakeRoomDataGuest
                                style={style}
                                branchID={branchID}
                                lockQuickSelect={idRoom !== "make_waiting"}
                                updateListGuest={updateListGuest}
                                makeRoomOnState={makeRoomOnState}
                                onChangeValueMakeRoom={onChangeValueMakeRoom}
                                handleChangeListChangeGuest={handleChangeListChangeGuest}
                                onChangeGuestDataAfterEdit={onChangeGuestDataAfterEdit}
                                changeViewPassPort={changeViewPassPort}
                            />
                            {
                                makeRoomOnState.viewPassPort ? <MakeRoomDataRoom
                                    makeRoomOnState={makeRoomOnState}
                                    onSortListSchedule={onSortListSchedule}
                                    onAddMoreSameListSchedule={onAddMoreSameListSchedule}
                                    onChangeValueMakeRoom={onChangeValueMakeRoom}
                                    onChangeAllDataValueMakeRoom={onChangeAllDataValueMakeRoom}
                                    onChangeValueMakeRoomOther={onChangeValueMakeRoomOther}
                                    onOpenSettingRequest1={onOpenSettingRequest1}
                                    onConfirmDeleteSchedule={onConfirmDeleteSchedule}
                                    onReturnDefaultSignature={onReturnDefaultSignature}
                                    onUpdateSignature={onUpdateSignature}
                                    viewFullPassPort={viewFullPassPort}
                                    style={style} /> : <ViewPassPortGuest
                             //       onChangeValueMakeRoom={onChangeValueMakeRoom} 
                                    />
                            }
                            {/* {
                                !makeRoomOnState.viewPassPort && <ViewPassPortGuest
                                    onChangeValueMakeRoom={onChangeValueMakeRoom} />
                            } */}
                        </div>

                        <MakeRoomFooterButton
                            idRoom={idRoom}
                            id_history={id_history}
                            option = {{
                                "isHiddenBtn": (Number(branchID) !== 11) ? makeRoomOnState.listSchedule[0].nights : ""
                               }}
                            idGuest={makeRoomOnState.listGuestSelect.length > 0 ? makeRoomOnState.listGuestSelect[0]._id : ""}
                            makeRoomService={makeRoomService}
                            onConfirmMakeRoom={click && onConfirmMakeRoom}
                            onConfirmCheckRoomStatus={onConfirmCheckRoomStatus}
                            onViewHistory={onViewHistory}
                            onConfirmEdit={click && onConfirmEdit}
                            onPrintBooking={onPrintBooking}
                            onConfirmRedo={onConfirmRedo}
                            onConfirmMakeHotelInvoice={onConfirmMakeHotelInvoice}
                            onConfirmDeleteSchedule={onConfirmDeleteSchedule}
                            onViewMailFormat={makeRoomOnState.listGuestSelect.length > 0 && onViewMailFormat}
                        />
                    </ div>
                </div>
            }
            
        </UserContext.Provider>
    )
};

export default MakeRoomContainer;
