import React from 'react';
import './RCSumDropDown.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function DropDownAction(props) {
    const { value, dropDownItem, target, indexData, width, widthInput , widthItem , dropdownMenu, className} = props;

    return (
        <React.Fragment>
            <div className="dropdown show" style={{ width: width }}>
                <div className="btn-group dropdown-toggle">
                    <input
                        className={" dropdown-toggle namesub__btn " + className}
                        type="button"
                        maxlength="120"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        //  onBlur={props.onBlur}
                        value={value}
                        style={{ width: widthInput }}
                        onChange={e => props.onChange(e.target.value, target, indexData)}
                    >
                    </input>

                    <div className="dropdown-menu p-1"
                        style={dropdownMenu ? dropdownMenu :{}}
                        aria-labelledby="dropdownMenuButton">
                        <input
                            maxlength="120"
                            type="text"
                            className={'namesub__btn dropdown-menu-input ' + className}
                            placeholder="change here"
                            //  onBlur={props.onBlur}
                            value={value}
                            style={{ width: widthInput }}
                            onChange={e => props.onChange(e.target.value, target, indexData)}
                        />
                        <div className='d-flex'>
                           <div style={{width: widthItem ? widthItem :"100%", fontSize:'1.2rem'}}>
                                <div className="bg_yellow" style={{ width: dropDownItem.blue ? "" : widthInput }}>
                                    {renderDropDown(dropDownItem.yellow ? dropDownItem.yellow : [])}
                                </div>
                                <div className="bg_prime_blur_color" style={{ width: dropDownItem.blue ? "" : widthInput }}>
                                    {renderDropDown(dropDownItem.prime ? dropDownItem.prime : [])}
                                </div>
                            </div>

                            <div style={{width: widthItem ? widthItem :"100%", fontSize:'1.2rem'}}>
                                <div className={dropDownItem.blue ? "bg_Regent_St_Blue" : "op-hidden"}>
                                    {renderDropDown(dropDownItem.blue ? dropDownItem.blue : [])}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >
    );

    function renderDropDown(ddItem) {
        var result = null;
        result = ddItem.map((Item, index) => {
            return (
                <span
                    className="dropdown-item d-flex justify-content-between"
                    key={index}
                    onClick={() => props.onChange(Item, target, indexData)}
                >{Item}
                </span>
            )
        })
        return result;
    }
}

export default DropDownAction;